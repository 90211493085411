import { Config } from './index';

const config: Config = {
  countryCode: 'se',
  defaultLocale: 'sv',
  currencySign: 'kr',
  checkoutUrl: 'https://bestall.freska.se',
  siteUrl: 'https://www.freska.se',
  appStoreUrl: 'https://itunes.apple.com/se/app/freska/id1397364064?mt=8',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=net.freska.freskaapp',
  termsAndConditionsUrl: {
    en: 'https://www.freska.se/en/terms-and-conditions',
    sv: 'https://www.freska.se/allmanna-villkor',
  },
  privacyPolicyUrl: {
    en: 'https://www.freska.se/en/privacy-policy',
    sv: 'https://www.freska.se/integritets-och-cookiespolicy',
  },
  freskaAppUrl: {
    en: 'https://www.freska.se/en/freska-app',
    sv: 'https://www.freska.se/freskas-app',
  },
};

export default config;
